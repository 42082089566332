import { createSelector, createSlice } from "@reduxjs/toolkit";
import { generateServerSeed } from "../thunk/user.thunk";

const initialState = {
  systemGameData: null,
  isBetPlaced: false,
  allCurrencies: [],
  gameSettings: [],
  gameSettingLoader: false,
  clientSeed: "",
  serverSeedHash: "",
  gameMusic: false,
  mobileActiveTab: 0,
};

const {
  actions: {
    setSystemData,
    setIsBetPlaced,
    setAllcurrency,
    setGameSettings,
    setGameSettingLoader,
    setNewSeed,
    setServerSeedHash,
    setGameMusic,
    setMobileActiveTab,
  },
  reducer,
} = createSlice({
  name: "gameSetting",
  initialState,
  reducers: {
    setMobileActiveTab: (state, action) => ({
      ...state,
      mobileActiveTab: action.payload,
    }),

    setSystemData: (state, action) => ({
      ...state,
      systemGameData: action.payload,
    }),
    setIsBetPlaced: (state, action) => ({
      ...state,
      isBetPlaced: action.payload,
    }),
    setAllcurrency: (state, action) => ({
      ...state,
      allCurrencies: action.payload,
    }),
    setGameSettings: (state, action) => ({
      ...state,
      gameSettings: action.payload,
    }),
    setGameSettingLoader: (state, action) => ({
      ...state,
      setGameSettingLoader: action.payload,
    }),
    setNewSeed: (state, action) => ({
      ...state,
      clientSeed: action.payload,
    }),
    setServerSeedHash: (state, action) => ({
      ...state,
      serverSeedHash: action.payload,
    }),
    setGameMusic: (state, action) => ({
      ...state,
      gameMusic: !state.gameMusic,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateServerSeed.fulfilled, (state, action) => {
        return {
          ...state,
          serverSeedHash: action.payload?.serverSeedHash ?? "",
        };
      })
      .addCase(generateServerSeed.rejected, (state, action) => {
        return {
          ...state,
        };
      });
  },
});

export default reducer;
const selectSelf = (state) => state.gameSetting;
export const selectMobileActiveTab = createSelector(
  selectSelf,
  (state) => state.mobileActiveTab
);
export {
  setSystemData,
  setIsBetPlaced,
  setAllcurrency,
  setGameSettings,
  setGameSettingLoader,
  setNewSeed,
  setServerSeedHash,
  setGameMusic,
  setMobileActiveTab,
};
