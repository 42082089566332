import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import routes from "./routesList";
// import PlinkoGameHeader from "../containers/GameHeader/PlinkoGameHeader";
const PlinkoGameHeader = lazy(() =>
  import("../containers/GameHeader/PlinkoGameHeader")
);
const GameHeader = lazy(() => import("../containers/GameHeader/index"));
const NotFoundComponent = lazy(() => import("../components/404"));
const HealthCheck = lazy(() => import("../components/HealthCheck"));

const RouteRenderer = (route) => {
  const { component: Component, child: Child } = route;
  return (
    <>
      {window.location.pathname !== "/health-check" &&
        (window.location.pathname.includes("plinko") ||
        window.location.pathname.includes("hi-lo") ||
        window.location.pathname.includes("keno") ? (
          <PlinkoGameHeader />
        ) : (
          <GameHeader />
        ))}
      {/* <GameHeader /> */}
      {Component && <Component child={Child} />}
    </>
  );
};
const CustomRoutes = () => (
  <>
    <Switch>
      {routes.map((route) => (
        <Route
          path={route.path}
          key={route.path}
          exact
          render={() => RouteRenderer(route)}
        />
      ))}
      <Route path="/health-check" component={HealthCheck} />
      <Route component={() => <NotFoundComponent />} />
    </Switch>
    {/* {window.location.pathname !== '/health-check' && <Footer />} */}
  </>
);

export default CustomRoutes;
