import React, { useEffect, useState } from "react";

export function ResponsiveBox(props) {
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        minWidth: "100vw",
        maxWidth: "100vw",
        minHeight: "100vh",
        maxHeight: "100vh",
        // overflow: "hidden",
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      }}
    >
      <div
        style={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          // margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          height: props.height,
          minHeight: props.height,
          width: props.width,
          minWidth: props.width,
          transform: `scale(${
            windowSize.innerWidth / props.width >
            windowSize.innerHeight / props.height
              ? windowSize.innerHeight / props.height
              : windowSize.innerWidth / props.width
          } )`,
        }}
        className={props.boxClass}
      >
        {props.children}
      </div>
    </div>
  );
}
