import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsDE from "./locales/de";
import translationsEN from "./locales/en/index";
import translationsPT from "./locales/pt";
import translationsTR from "./locales/tr";

i18n.use(initReactI18next).init({
  fallbackLng: "us",
  debug: false,
  saveMissing: true,
  resources: {
    us: {
      ...translationsEN,
    },
    tr: {
      ...translationsTR,
    },
    pt: { ...translationsPT },
    br: { ...translationsPT },
    de: { ...translationsDE },
  },
  // lng: loginSession.getLocale || 'en',
  nsSeparator: "::",
  interpolation: {
    escapeValue: false,
  },
});

const runTimeTranslations = (runTimeData, lng) => {
  i18n.addResourceBundle(lng, ["main"], runTimeData);
};

export { i18n, runTimeTranslations };
